<template>
<div>
    <page-header
      :title="'Admins'"
      :sub-heading="true"
      :sub-header="'Dashboard / Admin'"
      :link-name="'Add User'"
      :link-url="'/admin/new'"
    >
    </page-header>

    <full-page-content-container>
        <div class="row">
            <form class="form col-xs-12">
            <div class="form__group">
                <label for="firstName" class="form__label">First Name</label>
                <input
                type="text"
                class="form__input"
                placeholder="e.g Johnathan"
                id="firstName"
                v-model="user.first_name"
                name="first_name"
                />
            </div>

            <div class="form__group">
                <label for="lastName" class="form__label">Last Name</label>
                <input
                type="text"
                class="form__input"
                placeholder="e.g Doe"
                id="lastName"
                v-model="user.last_name"
                name="last_name"
                />
            </div>

            <div class="form__group">
                <label for="email" class="form__label">Email Address</label>
                <input
                type="email"
                class="form__input"
                placeholder="e.g Johanathandoe@gmail.com"
                id="email"
                v-model="user.email"
                name="email"
                />
            </div>
              <div class="form__group">
                <label for="password" class="form__label">Password</label>
                <input
                type="password"
                class="form__input"
                placeholder="password"
                id="password"
                v-model="user.password"
                name="password"
                />
            </div>

            <div class="form__group">
                <label for="default_role" class="form__label">Role</label>
                <select
                name="default_role"
                id="default_role"
                class="form__input"
                v-model="user.role"
                >
                <option disabled value="">Select User Role</option>
                <option
                    v-for="role in roles"
                    :key="role.id"
                    :value="role.id"
                >
                    {{ role.display_name }}
                </option>
                </select>
            </div>
            
            <!--      <div class="form__group">-->
            <!--        <label for="role" class="form__label">Default Role</label>-->
            <!--        <input-->
            <!--          type="text"-->
            <!--          class="form__input"-->
            <!--          placeholder="e.g Startup"-->
            <!--          id="role"-->
            <!--          v-model="user.role"-->
            <!--          name="role"-->
            <!--        />-->
            <!--      </div>-->

            <div class="form__group">
                <ca-button @click.native="Adduser" :disabled="loading">
                <span>Create User</span>
                <span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span>
                </ca-button>
            </div>
            </form>
        </div>
    </full-page-content-container>
</div>
</template>

<script>
import CaButton from "../../../components/buttons/CaButton";
import PageHeader from "../../../layouts/components/fullPage/PageHeader";
import axios from "../../../../axios";
import FullPageContentContainer from '../../../layouts/components/fullPage/FullPageContentContainer.vue';

export default {
  name: "EditUser",
  components: { CaButton, FullPageContentContainer, PageHeader },
  data() {
    return {
      loading: false,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        company_name: 'caena',
        role: 0,
      },
      roles: [],
    };
  },
  methods: {

    Adduser() {
      this.loading = true;
      axios
        .post(
          `${this.$store.state.server.requestUri}/admin/user`,
          this.user
        )
        .then(() => {
          this.$toast("User Added successfully", {
            position: "top-center",
            type: "success",
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    getRoles() {
      axios
        .get(`${this.$store.state.server.requestUri}/admin/roles`)
        .then((res) => {
          this.roles = res.data.data;
        })
        .catch((err) => console.log(err.response));
    },
  },
  created() {
    this.getRoles();
  },
};
</script>

<style scoped></style>
